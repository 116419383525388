<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    overlay-opacity="0.9"
    scrollable
    @update:modelValue="close"
  >
    <v-card>
      <v-card-title class="d-flex justify-space-between align-start">
        Filtrar vendas por setor
      </v-card-title>
      <v-card-text class="d-flex flex-column gap-3">
        <v-form v-model="valid" @submit.prevent="confirm">
          <v-card outlined class="rounded-lg">
            <v-card-text>
              <v-switch
                v-model="filterByDate"
                label="Filtrar por data"
                hide-details
                class="mt-0 pt-0"
              />
              <template v-if="filterByDate">
                <v-divider class="my-2"></v-divider>
                <v-chip-group
                  v-model="dateFilterPredefined"
                  column
                  class="mb-2"
                  color="primary"
                >
                  <v-chip
                    v-for="option in dateFilterPredefinedOptions"
                    :key="option.label"
                    small
                  >
                    {{ option.label }}
                  </v-chip>
                </v-chip-group>
                <div class="d-flex gap-2">
                  <v-text-field
                    v-model="dateFilter.start"
                    label="Data de início"
                    type="datetime-local"
                    :rules="[(v) => !!v || 'Data de início é obrigatória']"
                    outlined
                    class="flex-1"
                    hide-details="auto"
                  />
                  <v-text-field
                    v-model="dateFilter.end"
                    label="Data de fim"
                    type="datetime-local"
                    :rules="[(v) => !!v || 'Data de fim é obrigatória']"
                    outlined
                    class="flex-1"
                    hide-details="auto"
                  />
                </div>
              </template>
            </v-card-text>
          </v-card>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="close"> Cancelar </v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="confirm" color="primary" :disabled="!valid">
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import ApexCharts from "vue-apexcharts";
import ExcelExport from "@/utils/exportExcel/salesByGroup";

export default {
  name: "SalesByTicketBlockExcelFilter",
  components: { ApexCharts },
  data: () => ({
    dialog: false,
    filterByDate: false,
    dateFilterPredefined: null,
    dateFilterPredefinedOptions: [
      {
        label: "Hoje",
        start: moment().startOf("day"),
        end: moment().endOf("day"),
      },
      {
        label: "Ontem",
        start: moment().subtract(1, "day").startOf("day"),
        end: moment().subtract(1, "day").endOf("day"),
      },
      {
        label: "Essa semana",
        start: moment().startOf("week"),
        end: moment().endOf("week"),
      },
      {
        label: "Semana passada",
        start: moment().subtract(1, "week").startOf("week"),
        end: moment().subtract(1, "week").endOf("week"),
      },
      {
        label: "Esse mês",
        start: moment().startOf("month"),
        end: moment().endOf("month"),
      },
      {
        label: "Mês passado",
        start: moment().subtract(1, "month").startOf("month"),
        end: moment().subtract(1, "month").endOf("month"),
      },
    ],
    dateFilter: {
      start: null,
      end: null,
    },

    valid: false,
  }),
  computed: {
    filter() {
      const filter = {};
      if (this.filterByDate) {
        filter.start = this.dateFilter.start;
        filter.end = this.dateFilter.end;
      }
      return filter;
    },
  },
  methods: {
    open() {
      this.dialog = true;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    confirm() {
      this.dialog = false;
      this.resolve(this.filter);
    },
    close() {
      this.dialog = false;
      this.resolve();
    },
  },
  watch: {
    dateFilterPredefined(value) {
      if (value !== undefined) {
        if (this.dateFilterPredefinedOptions[value]) {
          this.dateFilter.start = this.dateFilterPredefinedOptions[
            value
          ].start.format("YYYY-MM-DDTHH:mm:ss");
          this.dateFilter.end = this.dateFilterPredefinedOptions[
            value
          ].end.format("YYYY-MM-DDTHH:mm:ss");
        }
      }
    },
  },
};
</script>

<style></style>
