<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="800px"
      overlay-opacity="0.9"
      scrollable
      :persistent="exporting"
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between align-start">
          Detalhes por Setor
          <div class="d-flex align-center">
            <v-btn
              x-small
              @click="generateExcel"
              color="primary"
              :disabled="!ticketGroups.length || exporting"
              :loading="exporting"
              class="rounded-r-0"
            >
              <v-icon left x-small color="white">mdi-file-excel</v-icon>
              Exportar
            </v-btn>
            <v-divider vertical></v-divider>
            <v-btn
              x-small
              color="primary"
              @click="openFilter"
              :disabled="exporting"
              class="rounded-l-0"
            >
              <v-icon x-small color="white">mdi-filter</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text class="d-flex flex-column gap-3">
          <v-card outlined>
            <v-card-text class="d-flex flex-wrap justify-space-around">
              <div class="text-center px-4">
                <h6 class="mt-2 mb-0">
                  {{ summary.sale }}
                </h6>
                <span class="text-caption">Vendidos</span>
              </div>
              <div class="text-center px-4">
                <h6 class="mt-2 mb-0">
                  {{ summary.courtesy | number }}
                </h6>
                <span class="text-caption">Cortesias</span>
              </div>
              <div class="text-center px-4">
                <h6 class="mt-2 mb-0">
                  {{ summary.count | number }}
                </h6>
                <span class="text-caption">Total</span>
              </div>
              <div class="text-center px-4">
                <h6 class="mt-2 mb-0">
                  {{ summary.validated | number }}
                </h6>
                <span class="text-caption">Validados</span>
              </div>
              <div class="text-center px-4">
                <h6 class="mt-2 mb-0">
                  {{ summary.value | currency }}
                </h6>
                <span class="text-caption">Valor Total</span>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            v-for="group in ticketGroups"
            :key="group.id"
            outlined
            class="pa-3"
          >
            <b>{{ group.name }}</b>

            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left w-full">Lote</th>
                    <th class="text-right">Valor</th>
                    <th class="text-center">Venda</th>
                    <th class="text-center">Cortesia</th>
                    <th class="text-right">Total</th>
                    <th class="text-right">Válidados</th>
                    <th class="text-right">Valor Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="block in group.TicketBlock"
                    :key="block.id"
                    style="border-top: 1px solid red !important"
                  >
                    <td class="font-weight-medium w-full">
                      {{ block.name }}
                    </td>
                    <td class="text-right">
                      {{ block.originalPrice | currency }}
                    </td>
                    <td class="text-center">{{ block.sale }}</td>
                    <td class="text-center">{{ block.courtesy }}</td>
                    <td class="text-center">{{ block.count }}</td>
                    <td class="text-center">{{ block.validated }}</td>
                    <td class="text-right">{{ block.value | currency }}</td>
                  </tr>
                  <tr
                    class="font-weight-bold"
                    style="border-top: 2px solid #000 !important"
                  >
                    <td class="font-weight-bold text-end text-15 w-full">
                      Total
                    </td>
                    <td></td>
                    <td class="text-center text-15">{{ group.total.sale }}</td>
                    <td class="text-center text-15">
                      {{ group.total.courtesy }}
                    </td>
                    <td class="text-center text-15">{{ group.total.count }}</td>
                    <td class="text-center text-15">
                      {{ group.total.validated }}
                    </td>
                    <td class="text-right text-15">
                      {{ group.total.value | currency }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="exporting" block text @click="dialog = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SalesByTicketBlockExcelFilter ref="filter" />
  </div>
</template>

<script>
import ApexCharts from "vue-apexcharts";
import ExcelExport from "@/utils/exportExcel/salesByGroup";
import SalesByTicketBlockExcelFilter from "./SalesByTicketBlockExcelFilter.vue";

export default {
  name: "SalesByTicketBlockModal",
  components: { ApexCharts, SalesByTicketBlockExcelFilter },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  data: () => ({
    dialog: false,
    exporting: false,
  }),
  computed: {
    validTickets() {
      return this.data.raw.tickets.filter(
        (t) => t.Payment.status === "succeeded"
      );
    },
    ticketBlocksMap() {
      return this.data.ticketGroups.reduce((acc, g) => {
        g.TicketBlock.forEach((b) => {
          acc[b.id] = b;
        });
        return acc;
      }, {});
    },

    ticketGroups() {
      return Object.values(
        this.validTickets.reduce((acc, t) => {
          const block = t.TicketBlock;
          const group = block.TicketGroup;
          const isCourtesy = t.Payment.paymentType === "COURTESY";
          if (!acc[group.id])
            acc[group.id] = {
              name: group.name,
              id: group.id,
              TicketBlock: {},
              total: {
                count: 0,
                sale: 0,
                value: 0,
                courtesy: 0,
                validated: 0,
              },
            };

          acc[group.id].total.count++;
          acc[group.id].total.value += t.amount - t.platformFee;
          if (isCourtesy) acc[group.id].total.courtesy++;
          else acc[group.id].total.sale++;
          if (t._count.TicketEntry) acc[group.id].total.validated++;

          if (!acc[group.id].TicketBlock[block.id])
            acc[group.id].TicketBlock[block.id] = {
              originalPrice: this.ticketBlocksMap[block.id]?.price,
              name: block.name,
              count: 0,
              sale: 0,
              value: 0,
              courtesy: 0,
              validated: 0,
            };

          acc[group.id].TicketBlock[block.id].count++;
          acc[group.id].TicketBlock[block.id].value += t.amount - t.platformFee;
          if (isCourtesy) acc[group.id].TicketBlock[block.id].courtesy++;
          else acc[group.id].TicketBlock[block.id].sale++;
          if (t._count.TicketEntry)
            acc[group.id].TicketBlock[block.id].validated++;

          return acc;
        }, {})
      )
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((group) => {
          group.TicketBlock = Object.values(group.TicketBlock).sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          return group;
        });
    },
    summary() {
      return this.ticketGroups.reduce(
        (acc, g) => {
          acc.count += g.total.count;
          acc.sale += g.total.sale;
          acc.value += g.total.value;
          acc.courtesy += g.total.courtesy;
          acc.validated += g.total.validated;
          return acc;
        },
        { count: 0, sale: 0, value: 0, courtesy: 0, validated: 0 }
      );
    },
    series() {
      return [
        {
          name: "Vendidos",
          data: this.ticketGroups.map((g) => g.total.sale),
        },
        {
          name: "Cortesias",
          data: this.ticketGroups.map((g) => g.total.courtesy),
        },
      ];
    },
    options() {
      return {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: { show: false },
        },
        labels: this.ticketGroups.map((g) => g.name),
        dataLabels: { enabled: false },
        plotOptions: {
          bar: {
            borderRadius: 4,
          },
        },
        yaxis: {
          labels: {
            formatter: (value) =>
              `${value.toFixed(1).replace(".0", "")} ingressos${
                value != 1 ? "s" : ""
              }`,
          },
        },
      };
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },
    async generateExcel(filters) {
      try {
        this.exporting = true;
        await ExcelExport({
          party: this.data.party,
          ticketGroups: this.ticketGroups,
          ticketBlocksMap: this.ticketBlocksMap,
          tickets: this.validTickets,
          filters,
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.exporting = false;
      }
    },
    async openFilter() {
      this.exporting = true;
      const filters = await this.$refs.filter.open();
      console.log(filters);
      if (filters) {
        this.generateExcel(filters);
      } else {
        this.exporting = false;
      }
    },
  },
};
</script>

<style></style>
