const { VUE_APP_PLATFORM_NAME } = process.env;

const ExcelJS = require("exceljs");
const moment = require("moment");

const downloadFile = require("./utils/downloadFile");
const header = require("./modules/header");
const formatName = require("./utils/formatName");
const PAYMENT = require("../payment.js").default;

const store = require("../../store").default;

module.exports = async ({ party, ticketGroups, tickets, filters, ticketBlocksMap }) => {
  const opMode = store?.getters["auth/config"]?.opMode || false;

  const workbook = new ExcelJS.Workbook();
  workbook.creator = VUE_APP_PLATFORM_NAME;
  workbook.lastModifiedBy = VUE_APP_PLATFORM_NAME;

  workbook.title = `Vendas por setor - ${formatName(party.name)}`;
  workbook.created = new Date();

  const sheets = {
    resume: workbook.addWorksheet("Resumo", {
      properties: { tabColor: { argb: "242939" } },
      views: [{ showGridLines: false }]
    }),
    online: workbook.addWorksheet("Resumo Online", {
      properties: { tabColor: { argb: "242939" } },
      views: [{ showGridLines: false }]
    }),
    pos: workbook.addWorksheet("Resumo Pos", {
      properties: { tabColor: { argb: "242939" } },
      views: [{ showGridLines: false }]
    }),
  }


  const interestedData = () => ({
    count: 0,
    sale: 0,
    value: 0,
    courtesy: 0,
    validated: 0,
  })
  const reducedTickets = tickets.reduce((acc, t) => {
    const ticketDate = t.Payment.payedAt || t.Payment.createdAt;
    if (filters?.start && moment(ticketDate).isBefore(filters.start)) return acc;
    if (filters?.end && moment(ticketDate).isAfter(filters.end)) return acc;

    const block = t.TicketBlock;
    const group = block.TicketGroup;
    const isCourtesy = t.Payment.paymentType === "COURTESY";
    const paymentMethodKey = t.Payment.paymentMethod === "POS" ? "pos" : "online";



    ['resume', paymentMethodKey].forEach(key => {
      if (!acc[key][group.id])
        acc[key][group.id] = {
          name: group.name,
          id: group.id,
          TicketBlock: {},
          total: interestedData(),
        };

      acc[key][group.id].total.count++;
      acc[key][group.id].total.value += t.amount - t.platformFee;
      if (isCourtesy) acc[key][group.id].total.courtesy++;
      else acc[key][group.id].total.sale++;
      if (t._count.TicketEntry) acc[key][group.id].total.validated++;

      if (!acc[key][group.id].TicketBlock[block.id])
        acc[key][group.id].TicketBlock[block.id] = {
          originalPrice: ticketBlocksMap[block.id]?.price,
          name: block.name,
          ...interestedData(),
        };

      acc[key][group.id].TicketBlock[block.id].count++;
      acc[key][group.id].TicketBlock[block.id].value += t.amount - t.platformFee;
      if (isCourtesy) acc[key][group.id].TicketBlock[block.id].courtesy++;
      else acc[key][group.id].TicketBlock[block.id].sale++;
      if (t._count.TicketEntry)
        acc[key][group.id].TicketBlock[block.id].validated++;
    });
    return acc;
  }, {
    resume: {},
    online: {},
    pos: {},
  });

  for (const key of Object.keys(reducedTickets)) {
    const sheet = sheets[key];
    const data = reducedTickets[key];
    const ticketGroups = Object.values(data);
    const columns = [
      { key: "name", width: 50 },
      { key: "sales", width: 15 },
      { key: "courtesy", width: 18 },
      { key: "totalTickets", width: 20 },
      { key: "validated", width: 20 },
      { key: "averageTicket", width: 20 },
      { key: "totalValue", width: 20 },
    ];
    sheet.columns = columns.flat();
    sheet.columns.forEach((column, index) => {
      if (!index) column.alignment = { vertical: "middle", horizontal: "left" };
      else column.alignment = { vertical: "middle", horizontal: "center" };
    });
    applyStyle(sheet, {
      font: { name: "Courier New" }
    });


    await header(workbook, sheet, { title: "Vendas por setor", party, filters });

    // Dados
    const lastColumn = String.fromCharCode(65 + columns.length - 1);
    ticketGroups.forEach((ticketGroup) => {
      const r = sheet.addRow({ name: ticketGroup.name });
      r.font = { bold: true, size: 14 };
      r.alignment = { horizontal: "center" };
      r.height = 50;
      r.eachCell((cell) => { cell.border = { bottom: { style: "thin" }, top: { style: "medium" } }; })


      // header 
      sheet.mergeCells(`A${r.number}:${lastColumn}${r.number}`);
      for (let i = 1; i <= columns.length; i++) {
        r.getCell(i).font = { name: "Courier New", bold: true, size: 12, color: { argb: "295f99" } };
      }

      // add columns
      const hRow = sheet.addRow({
        name: `Setor • ${ticketGroup.name}`,
        sales: "Vendas",
        courtesy: "Cortesias",
        totalTickets: "Total Ingressos",
        averageTicket: "Ticket Médio",
        validated: "Validados",
        totalValue: "Valor Total",
      });
      hRow.font = { name: "Courier New", bold: true, size: 12 };
      const ticketBlocks = Object.values(ticketGroup.TicketBlock);
      ticketBlocks.forEach((ticketBlock) => {
        var tbRow = sheet.addRow({
          name: ticketBlock.name,
          sales: ticketBlock.sale,
          courtesy: ticketBlock.courtesy,
          totalTickets: ticketBlock.count,
          averageTicket: {
            formula: `G${sheet.rowCount + 1} / D${sheet.rowCount + 1}`,
            result: ticketBlock.value / ticketBlock.count,
          },
          validated: ticketBlock.validated,
          totalValue: ticketBlock.value,
        });

        tbRow.getCell(6).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00';
        tbRow.getCell(7).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00';
      });

      // Total row
      const initialRow = sheet.rowCount - ticketGroup.TicketBlock.length;
      const totalRow = sheet.addRow({
        name: "Sub-Total",
        sales: ticketGroup.total.sale,
        courtesy: ticketGroup.total.courtesy,
        totalTickets: ticketGroup.total.count,
        validated: ticketGroup.total.validated,
        averageTicket: ticketGroup.total.value / ticketGroup.total.count,
        totalValue: ticketGroup.total.value,
      });

      totalRow.getCell(1).alignment = { horizontal: "right" };
      totalRow.getCell(6).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00';
      totalRow.getCell(7).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00';
      totalRow.font = { name: "Courier New", bold: true, color: { argb: "FF0000" } };
      totalRow.eachCell((cell) => { cell.border = { top: { style: "dotted" } }; });
      totalRow.height = 18;

      // Empty row
      sheet.addRow([]);
    });

    sheet.addRow([]);

    const totalValue = ticketGroups.reduce((acc, { total }) => {
      return {
        sale: acc.sale + total.sale,
        courtesy: acc.courtesy + total.courtesy,
        count: acc.count + total.count,
        value: acc.value + total.value,
        validated: acc.validated + total.validated,
      }
    }, { sale: 0, courtesy: 0, count: 0, value: 0, validated: 0 });

    const totalRow = sheet.addRow({
      name: "Total",
      sales: totalValue.sale,
      courtesy: totalValue.courtesy,
      totalTickets: totalValue.count,
      averageTicket: totalValue.value / totalValue.count,
      validated: totalValue.validated,
      totalValue: totalValue.value,
    });

    totalRow.height = 30;
    totalRow.font = { name: "Courier New", bold: true, color: { argb: "FF0000" } };
    totalRow.alignment = { vertical: "middle", horizontal: "center" };
    totalRow.getCell(6).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00';
    totalRow.getCell(7).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00';
    totalRow.getCell(1).alignment = { horizontal: "right", vertical: "middle" };
    totalRow.eachCell((cell, i) => {
      cell.border = {
        top: { style: "double" },
        bottom: { style: "double" },
        left: i === 1 ? { style: "double" } : undefined,
        right: i === 7 ? { style: "double" } : undefined,
      };
    });


    const timestempRow = sheet.addRow({ name: moment().format("DD [de] MMMM [de] YYYY   HH:mm:ss") });
    timestempRow.font = { size: 10 };
    timestempRow.alignment = { horizontal: "right" };
    timestempRow.height = 50;
    sheet.mergeCells(`A${timestempRow.number}:${lastColumn}${timestempRow.number}`);

    // Protect sheet
    if (!opMode) {
      const ramdomPassword = Math.random().toString(36).slice(-8);
      console.log("Excel protegido com a senha: ", ramdomPassword);
      sheet.protect(ramdomPassword, {
        sort: true,
        autoFilter: true,
        selectLockedCells: false,
      });
    }
  }

  // Transactions
  const sheetTransactions = workbook.addWorksheet("Ingressos", {
    properties: { tabColor: { argb: "242939" } },
  });

  const columnsTransactions = [
    { header: "#", key: "id", width: 12 },
    { header: "Data", key: "date", width: 22 },
    { header: "Setor", key: "group", width: 25 },
    { header: "Lote", key: "block", width: 25 },
    { header: "Válidado", key: "validated", width: 20 },
    { header: "Valor Líquido", key: "value", width: 20 },
    opMode ? { header: "Taxa Plataforma", key: "platformFee", width: 20 } : null,
    // opMode ? { header: "Taxa Cliente", key: "clientFee", width: 20 } : null,
    { header: "Meio de pagamento", key: "paymentMethod", width: 25 },
    { header: "Forma de pagamento", key: "paymentType", width: 25 },
  ].filter((column) => !!column);

  sheetTransactions.columns = columnsTransactions.flat();
  applyStyle(sheetTransactions, {
    font: { name: "Courier New" }
  });

  sheetTransactions.getRow(1).font = { bold: true, name: "Courier New" };
  sheetTransactions.columns.forEach((column, index) => {
    if (index < 5) column.alignment = { vertical: "middle", horizontal: "left" };
    else column.alignment = { vertical: "middle", horizontal: "center" }
  });

  tickets
    .sort((a, b) => a.Payment.createdAt - b.Payment.createdAt)
    .forEach((ticket) => {
      const ticketDate = ticket.Payment.payedAt || ticket.Payment.createdAt;
      if (filters?.start && moment(ticketDate).isBefore(filters.start)) return;
      if (filters?.end && moment(ticketDate).isAfter(filters.end)) return;


      const r = sheetTransactions.addRow({
        id: ticket.id.substring(0, 8),
        date: moment(ticket.Payment.payedAt || ticket.Payment.createdAt).format("DD/MM/YYYY HH:mm"),
        group: ticket.TicketBlock.TicketGroup.name,
        block: ticket.TicketBlock.name,
        validated: ticket._count.TicketEntry ? "Sim" : "Não",
        value: ticket.amount - ticket.platformFee,
        platformFee: ticket.platformFee || 0,
        // clientFee: ticket.clientFee || 0,
        paymentMethod: PAYMENT.paymentMethod[ticket.Payment.paymentMethod].text,
        paymentType: PAYMENT.paymentType[ticket.Payment.paymentType].text,
      });
      r.getCell(6).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00';
      if (opMode) r.getCell(7).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00';
    });





  downloadFile(workbook, `Vendas por Setor - ${party.name}`);
  return true;
};


function applyStyle(sheet, style) {
  const { font, alignment } = style;
  sheet.columns.forEach((column, index) => {
    if (font) column.font = font;
    if (alignment) column.alignment = alignment;
  });
}

