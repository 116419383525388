const axios = require("axios");
const moment = require("moment");
module.exports = async (workbook, sheet, { title = "", party, filters }) => {
  // Add a new row before the data and add a title
  const columnsLength = Math.min(sheet.columns.length, 6);
  const row = sheet.insertRow(1, []);
  const filterStemp = filters ? calculateFilterStemp(filters) : null;


  // set entire row background color
  row.height = 40;
  row.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "242939" },
  };
  row.font = { color: { argb: "ffffff" } };

  sheet.mergeCells(`A1:${String.fromCharCode(64 + columnsLength)}1`);
  row.getCell(1).value = title;
  row.getCell(1).style.font = { size: 22, bold: true, color: { argb: "ffffff" } };
  row.getCell(1).style.alignment = { horizontal: "center", vertical: "middle" };

  // Subtitle
  const subtitleRow = sheet.insertRow(2, []);
  subtitleRow.height = 25;
  subtitleRow.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "242939" },
  };
  subtitleRow.font = { color: { argb: "ffffff" } };

  sheet.mergeCells(`A2:${String.fromCharCode(64 + columnsLength)}2`);
  subtitleRow.getCell(1).style.font = { size: 14, bold: true, color: { argb: "ffffff" } };
  subtitleRow.getCell(1).style.alignment = {
    horizontal: "center",
    vertical: "top",
  };

  // filter row
  if (filterStemp) {
    const filterRow = sheet.insertRow(3, []);
    filterRow.height = 20;
    filterRow.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "242939" },
    };
    filterRow.font = { color: { argb: "ffffff" } };

    sheet.mergeCells(`A3:${String.fromCharCode(64 + columnsLength)}3`);
    filterRow.getCell(1).style.font = { size: 12, color: { argb: "ffffff" } };
    filterRow.getCell(1).style.alignment = {
      horizontal: "center",
      vertical: "top",
    };

    filterRow.getCell(1).value = filterStemp;
  }


  // Add logo
  var logoImgId = workbook.media.length ? 0 : null;
  if (logoImgId === null) {
    const logoBuffer = await axios.get("/img/logo/logo-light.png", {
      responseType: "arraybuffer",
    });

    logoImgId = workbook.addImage({
      buffer: logoBuffer.data,
      extension: "png",
    });
  }

  sheet.addImage(logoImgId, {
    tl: { col: 0.2, row: 0.6 },
    ext: { width: 150, height: 40 },
    editAs: "oneCell",
  });

  if (party) {
    subtitleRow.getCell(1).value = party.name

    var coverImgId = workbook.media.length > 1 ? 1 : null;
    if (!coverImgId) {
      const partyCoverBuffer = await axios.get(party.cover, {
        responseType: "arraybuffer",
      }).catch(() => false)
      if (!partyCoverBuffer) return true;
      coverImgId = workbook.addImage({
        buffer: partyCoverBuffer.data,
        extension: "png",
      });

    }
    sheet.addImage(coverImgId, {
      tl: { col: columnsLength - 0.5, row: 0.3 },
      ext: { height: 60, width: (16 / 9) * 60 },
      editAs: "oneCell",
    });
  }

  // Protect entire row
  row.protection = {
    locked: true,
    lockText: true,
  };

  return true;
};

function calculateFilterStemp(filtersObject) {
  const filters = [];

  if (filtersObject.start) filters.push(`Data de início: ${moment(filtersObject.start).format("DD/MM/YYYY HH:mm")}`);
  if (filtersObject.end) filters.push(`Data de término: ${moment(filtersObject.end).format("DD/MM/YYYY HH:mm")}`);

  if (!filters.length) return "";

  return `Filtros: ${filters.join(" - ")}`;
}
